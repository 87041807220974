import { render, staticRenderFns } from "./requestGoods.pug?vue&type=template&id=cbe4d31c&scoped=true&lang=pug&"
import script from "./requestGoods.ts?vue&type=script&lang=ts&"
export * from "./requestGoods.ts?vue&type=script&lang=ts&"
import style0 from "./requestGoods.scss?vue&type=style&index=0&id=cbe4d31c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cbe4d31c",
  null
  
)

export default component.exports