// node_module dependencies
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import forEach from 'lodash/forEach'
import startCase from 'lodash/startCase'

// Base Page
import BasePage from '@/pages/Base'

// Services
import RequestGoodsService from '@/services/RequestGoods'

@Component({})
export default class RequestGoods extends BasePage {
  constructor() {
    super()
  }

  // Filter
  filterLoading: boolean = false
  filterLabel: string = 'Filter by Status'
  filterHint: string = 'Apply Status Filters Here'
  filterItems: string[] = ['Pending', 'On Process', 'Found']
  filterIcon: string = 'mdi-filter-variant'
  filterSelected: string = ''

  // Search
  searchLoading: boolean = false
  searchInput: string = ''
  searchLabel: string = 'Search here...'
  searchHint: string = 'Search Anything Here'
  searchIcon: string = 'search'

  // Table
  tableHeaders: object[] = [
    {
      text: 'ID',
      align: 'left',
      sortable: true,
      value: 'id'
    },
    {
      text: 'Customer Name',
      align: 'left',
      sortable: false,
      value: 'user.full_name'
    },
    {
      text: 'Request Date',
      align: 'left',
      sortable: true,
      value: 'created_at'
    },
    {
      text: 'Goods',
      align: 'left',
      sortable: true,
      value: 'name'
    },
    {
      text: 'Price Range',
      align: 'left',
      sortable: true,
      value: 'min_price'
    },
    {
      text: 'Status',
      align: 'center',
      sortable: true,
      value: 'status'
    }
  ]
  tableItems: object[] = []
  tableTotalItems: number | string = 0
  tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
  tablePagination: any = {
    sortBy: 'created_at',
    page: 1,
    rowsPerPage: 25,
    descending: true,
    totalItems: 0
  }
  tableLoading: boolean = false

  @Watch('tablePagination', { deep: true })
  @Watch('filterSelected')
  onTablePaginationChanged() {
    this.$vuetify.goTo(0)
    this.getRequestGoodsData()
  }

  mounted() {
    this.getRequestGoodsData()
  }

  getRequestGoodsData() {
    const opts: any = {
      params: {
        page: this.tablePagination.page,
        limit: this.tablePagination.rowsPerPage,
        sortedBy: this.tablePagination.descending ? 'desc' : 'asc',
        orderBy: this.tablePagination.sortBy
      }
    }
    if (this.filterSelected != '') opts.params.search = this.filterSelected

    this.tableLoading = true
    RequestGoodsService.getRequestGoodsLists(opts)
      .then(response => {
        this.tableTotalItems = response.meta.pagination.total
        this.tablePagination.totalItems = response.meta.pagination.total
        this.tableItems = []

        forEach(response.data, item => {
          this.tableItems.push({
            value: false,
            requestId: item.id,
            customerName: item.user.full_name,
            requestDate: item.created_at,
            goods: item.name,
            currency: item.currency,
            min_price: item.min_price,
            max_price: item.max_price,
            status: startCase(item.status),
            image: item.file,
            detail: item.description == '' ? 'No description provided' : item.description
          })
        })
        this.tableLoading = false
      })
      .catch(error => this.catchHandler(error))
  }

  deleteRequestGood(id: string | number) {
    this.tableLoading = true
    RequestGoodsService.deleteRequestGood(id)
      .then(data => {
        this.getRequestGoodsData()
        this.tableLoading = false
      })
      .catch(error => this.catchHandler(error))
  }

  goToResponse(id: string | number) {
    this.$router.push(`/request-goods/response/${id}`)
  }
}
