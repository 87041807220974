import BaseService from '@/services/BaseService'

class RequestGoodsService extends BaseService {
  constructor() {
    super()
  }

  getSingleRequest(id: string | number, opts: object = {}) {
    this.endPoint = 'request-goods'
    return this.getOne(id, opts).then(response => {
      return response.data
    })
  }

  getRequestGoodsLists(opts: object = {}) {
    this.endPoint = 'request-goods'
    return this.get(opts).then(response => {
      return response.data
    })
  }

  deleteRequestGood(id: string | number, opts: object = {}) {
    this.endPoint = 'request-goods'
    return this.delete(id, opts).then(response => {
      return response.data
    })
  }

  getRequestGoodsRecommendationLists(id: string | number, opts: object = {}) {
    this.endPoint = `request-goods/${id}/recommendations`
    return this.get(opts).then(response => {
      return response.data
    })
  }

  addRecommendation(id: string | number, data: object, opts: object = {}) {
    this.endPoint = `request-goods/${id}/recommendations`
    return this.post(data, opts).then(response => {
      return response.data
    })
  }

  cancelRecommendation(id: string | number, data: object, opts: object = {}) {
    this.endPoint = `request-goods/${id}/delete-recommendations`
    return this.post(data, opts).then(response => {
      return response.data
    })
  }
}

export default new RequestGoodsService()
